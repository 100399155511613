import axios from 'axios';
import { ApiError } from '../../error';

const url = '/api/v1/tipdirect/groups';

export const fetchGroupByCardId = cardId => {
  return axios
    .get(`${url}/card/${cardId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const removeFromTeam = (groupId, type, id) => {
  return axios
    .post(`${url}/remove/${groupId}/${type}/${id}`)
    .then(res => {
      window.location.reload();
      return res.data.data;
    })
    .catch(ApiError.ofAndThrow);
};

export const addToGroup = ({ groupId, type, id }) => {
  return axios
    .post(`${url}/add`, { groupId, type, id })
    .then(res => {
      window.location.reload();
      return res.data.data;
    })
    .catch(ApiError.ofAndThrow);
};

export const fetchTeamDetails = groupId => {
  return axios
    .get(`${url}/${groupId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const saveSplitBreakdown = splitBreakdown => {
  return axios
    .post(`${url}/adjust`, splitBreakdown)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
