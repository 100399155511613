import { Analytics } from '@analytics';
import { addToGroup, fetchGroupByCardId } from '@api/taptotip/group';
import ConfirmIcon from '@assets/images/ConfirmIcon.svg';
import EmojiAvatar from '@components/EmojiAvatar';
import TopNav from '@components/Nav/TopNav';
import GroupCard from '@components/TapToTip/Groups/GroupCard';
import GroupDetailsCard from '@components/TapToTip/Groups/GroupDetailsCard';
import MobileConfirmationDialog from '@components/TapToTip/MobileConfirmationDialog';
import { GroupEntityType, StyleBreakpoints } from '@constants';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle as AccountIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#f9f9f9',
    padding: '24px 18px',
    borderRadius: '14px'
  },
  infoContainer: {
    marginBottom: '24px'
  },
  button: {
    width: '100%',
    height: '39px',
    backgroundColor: '#6997ff',
    color: 'white',
    marginTop: '24px',

    '&:hover': {
      backgroundColor: '#6982ff'
    },

    '&:disabled': {
      backgroundColor: '#dadbdb',
      color: '#fff',

      '&:hover': {
        backgroundColor: '#ccc'
      }
    }
  },
  info: {
    padding: '16px',
    display: 'flex',
    height: 'fit-content',
    maxWidth: '90%',
    margin: '8px auto',
    alignItems: 'center'
  },
  infoText: {
    display: 'flex',
    flexDirection: 'column'
  },
  infoIcon: {
    marginRight: '12px'
  },
  boldText: {
    fontWeight: 'bold'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  user: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  userName: {
    fontWeight: 'bold',
    fontSize: '18px'
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1e2338',
    borderRadius: '12px',
    minHeight: '200px',
    padding: '45px',
    textAlign: 'center',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      maxWidth: '400px'
    }
  },
  title: {
    fontSize: '16px',
    color: '#ffffff',
    fontWeight: '500'
  },
  description: {
    fontSize: '12px',
    color: '#a0a0a0',
    fontWeight: '500'
  },
  icon: {
    display: 'flex',
    height: '60px',
    width: '60px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contents: {
    padding: '8px',
    margin: 'auto',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      maxWidth: '400px',
      padding: '16px'
    }
  },
  claimButton: {
    backgroundColor: '#018101',
    color: '#d8fdd8',

    '&:hover': {
      backgroundColor: '#339a33'
    }
  },
  cancelButton: {
    marginTop: '8px',
    color: '#a0a0a0'
  }
}));

const GroupPage = ({ user }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { cardId } = useParams();
  const [groupData, setGroupData] = useState(null);
  const [isUserInGroup, setIsUserInGroup] = useState(null);
  const [description, setDescription] = useState('');
  const [buttons, setButtons] = useState(null);
  const [isConfirmationDialogShown, setIsConfirmationDialogShown] = useState(false);
  const [dialogIcon, setDialogIcon] = useState(null);

  useEffect(() => {
    if (user && groupData && groupData.group) {
      setIsUserInGroup(!!groupData.group.users.find(groupUser => groupUser._id === user.userId));
    }
  }, [user, groupData]);

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        if (cardId) {
          const data = await fetchGroupByCardId(cardId);
          setGroupData(data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchGroupData();
  }, [cardId, user]);

  if (!groupData) {
    return (
      <center>
        <CircularProgress />
      </center>
    );
  }

  const handleAddToTeam = () => {
    Analytics.track('clicked add to team button');
    addToGroup({
      groupId: user.groupDetails._id,
      type: GroupEntityType.user,
      id: groupData.user._id
    });
  };

  const handleJoinTeamWithGuide = () => {
    Analytics.track('clicked create team with user button');
    addToGroup({
      type: GroupEntityType.user,
      id: groupData.user._id
    });
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogShown(false);
  };

  const showAddToTeamDialog = ({ type }) => {
    setDialogIcon(<img alt="confirm" src={ConfirmIcon} />);
    setDescription(
      user.groupDetails
        ? intl.formatMessage(messages.claimResourceInGroup)
        : intl.formatMessage(messages.claimResourceConfirmation)
    );

    setButtons([
      <Button
        className={classes.claimButton}
        key="confirm-button"
        onClick={() => {
          Analytics.track('confirm add to team', {
            type: GroupEntityType.resource
          });
          addToGroup({
            type,
            id: type === GroupEntityType.resource ? groupData.resource._id : groupData.user._id
          });
        }}>
        {intl.formatMessage(messages.claimResource)}
      </Button>,
      <Button
        className={classes.cancelButton}
        color="secondary"
        key="cancel-button"
        onClick={closeConfirmationDialog}>
        {intl.formatMessage(messages.cancel)}
      </Button>
    ]);

    setIsConfirmationDialogShown(true);
  };
  const renderInfoCard = type => {
    let properties = {};

    if (!groupData.group) {
      if (type === GroupEntityType.resource) {
        properties = {
          icon: groupData.resource.icon,
          title: groupData.resource.name,
          description: intl.formatMessage(messages.resourceNotPartOfTeam)
        };
      }

      if (!groupData.group && type === GroupEntityType.resource && user.groupDetails) {
        properties = {
          icon: groupData.resource.icon,
          title: groupData.resource.name,
          description: intl.formatMessage(messages.addResourceToTeam)
        };
      }

      if (!groupData.group && type === GroupEntityType.user && !user.groupDetails) {
        properties = {
          icon: groupData.user.profile.avatarUrl,
          title: groupData.user.profile.firstName,
          description: intl.formatMessage(messages.createNewTeamWithUser)
        };
      }

      if (!groupData.group && type === GroupEntityType.user && user.groupDetails) {
        properties = {
          icon: groupData.user.profile.avatarUrl,
          title: groupData.user.profile.firstName,
          description: intl.formatMessage(messages.addUserToTeam)
        };
      }
    }

    if (groupData.group) {
      if (type === GroupEntityType.resource) {
        properties = {
          icon: groupData.resource.icon,
          title: groupData.resource.name,
          description: intl.formatMessage(messages.createNewTeamWithResource)
        };
      }

      if (type === GroupEntityType.resource && user.groupDetails) {
        properties = {
          icon: groupData.resource.icon,
          title: groupData.resource.name,
          description: intl.formatMessage(messages.moveResourceToTeam)
        };
      }

      if (type === GroupEntityType.resource && user.groupDetails && isUserInGroup) {
        properties = {
          icon: groupData.resource.icon,
          title: groupData.resource.name,
          description: intl.formatMessage(messages.resourceIsPartOfTeam)
        };
      }

      if (type === GroupEntityType.user && !user.groupDetails) {
        properties = {
          icon: groupData.user.profile.avatarUrl,
          title: groupData.user.firstName,
          description: intl.formatMessage(messages.moveUserToNewTeam)
        };
      }

      if (type === GroupEntityType.user && user.groupDetails) {
        properties = {
          icon: groupData.user.profile.avatarUrl,
          title: groupData.user.profile.firstName,
          description: intl.formatMessage(messages.moveUserToExistingTeam)
        };
      }

      if (type === GroupEntityType.user && user.groupDetails && isUserInGroup) {
        properties = {
          icon: groupData.user.profile.avatarUrl,
          title: groupData.user.profile.firstName,
          description: intl.formatMessage(messages.userIsPartOfTeam)
        };
      }
    }

    return (
      <div className={`${classes.container} ${classes.infoContainer}`}>
        <div className={classes.infoBox}>
          {type === GroupEntityType.user && groupData.user.profile.avatarUrl && (
            <img className={classes.icon} alt="Group icon" src={properties.icon} />
          )}
          {type === GroupEntityType.user && !groupData.user.profile.avatarUrl && (
            <AccountIcon className={classes.icon} />
          )}
          {type === GroupEntityType.resource && (
            <div className={classes.icon}>
              <EmojiAvatar code={groupData.resource.emoji || ':credit_card:'} />
            </div>
          )}
          <div className={classes.title}>{properties.title}</div>
          <div className={classes.description}>{properties.description}</div>
        </div>
        {!isUserInGroup && type !== GroupEntityType.user && (
          <div className={classes.buttons}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                Analytics.track('click add to team', {
                  type: GroupEntityType.resource
                });
                showAddToTeamDialog({ type });
              }}>
              {`${intl.formatMessage(messages.add)} `}
              {type === GroupEntityType.resource
                ? groupData.resource.name
                : groupData.user.profile.firstName}
              {` ${intl.formatMessage(messages.toMyTeam)}`}
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <TopNav />
      <div className={classes.contents}>
        {/* card belongs to another user that is not in a group */}
        {!groupData.group && groupData.user && groupData.user._id !== user.userId && (
          <>
            {renderInfoCard(GroupEntityType.user)}
            <div className={classes.container}>
              <GroupDetailsCard
                type={GroupEntityType.user}
                isAGroup={false}
                isUserInGroup={isUserInGroup}
                elements={[groupData.user]}
                user={user}
              />
            </div>
          </>
        )}

        {/* the card belogs to the current user */}
        {!groupData.group && groupData.user && groupData.user._id === user.userId && (
          <div>{intl.formatMessage(messages.youOwnThisCard)}</div>
        )}

        {/* card belongs to a resource that is not in a group */}
        {!groupData.group && groupData.resource && (
          <>
            {renderInfoCard(GroupEntityType.resource)}

            <div className={classes.container}>
              <GroupDetailsCard
                isAGroup={false}
                type={GroupEntityType.resource}
                isUserInGroup={isUserInGroup}
                elements={[groupData.resource]}
                user={user}
              />
            </div>
          </>
        )}

        {/* card belongs to an element in an existing group */}
        {groupData.group && (
          <>
            {renderInfoCard(groupData.resource ? GroupEntityType.resource : GroupEntityType.user)}
            <div className={classes.container}>
              <GroupCard
                group={groupData.group}
                cardOwnerType={groupData.resource ? GroupEntityType.resource : GroupEntityType.user}
                cardOwnerElement={groupData.resource ? groupData.resource : groupData.user}
                user={user}
                isUserInGroup={isUserInGroup}
                defaultOpenState={true}
                setDialogIcon={setDialogIcon}
                setDescription={setDescription}
                setButtons={setButtons}
                setIsConfirmationDialogShown={setIsConfirmationDialogShown}
                closeConfirmationDialog={closeConfirmationDialog}
              />
            </div>
          </>
        )}

        {/* card belongs to a user not already in a team while active user has a team */}
        {!groupData.group &&
          groupData.user &&
          groupData.user._id !== user.userId &&
          user.groupDetails && (
            <Button key="add-button" className={classes.button} onClick={() => handleAddToTeam()}>
              {intl.formatMessage(messages.addUserToTeamButton)}
            </Button>
          )}

        {/* card belongs to a user not already in a team while active user does not have a team */}
        {!groupData.group &&
          groupData.user &&
          groupData.user._id !== user.userId &&
          !user.groupDetails && (
            <Button
              key="join-with-button"
              className={classes.button}
              onClick={() => {
                handleJoinTeamWithGuide();
              }}>
              {intl.formatMessage(messages.createNewTeam)}
            </Button>
          )}
      </div>
      <MobileConfirmationDialog
        isShown={isConfirmationDialogShown}
        buttons={buttons}
        description={description}
        close={closeConfirmationDialog}
        icon={dialogIcon}
      />
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user?.user
});

export default connect(mapStateToProps)(GroupPage);
