import { capitalize } from '@material-ui/core/styles';
import { endOfDay, format, parse, startOfDay } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import caLocale from 'date-fns/locale/ca';
import daLocale from 'date-fns/locale/da';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import fiLocale from 'date-fns/locale/fi';
import frLocale from 'date-fns/locale/fr';
import itLocale from 'date-fns/locale/it';
import jaLocale from 'date-fns/locale/ja';
import noLocale from 'date-fns/locale/nb';
import nlLocale from 'date-fns/locale/nl';
import plLocale from 'date-fns/locale/pl';
import ptLocale from 'date-fns/locale/pt';
import ruLocale from 'date-fns/locale/ru';
import svLocale from 'date-fns/locale/sv';
import { isNil } from 'ramda';
import { PaymentProviders, PaymentStatuses, SignupType, ZeroDecimalCurrencies } from './constants';

const localeMap = {
  en: enLocale,
  de: deLocale,
  es: esLocale,
  fr: frLocale,
  nl: nlLocale,
  pl: plLocale,
  pt: ptLocale,
  sv: svLocale,
  ja: jaLocale,
  da: daLocale,
  it: itLocale,
  no: noLocale,
  fi: fiLocale,
  ru: ruLocale,
  ca: caLocale
};

export function getDateFnsLocale(code = 'en') {
  return localeMap[code];
}

export function debounce(targetFunction, timeoutMs) {
  let timeout = null;

  return (...args) => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => targetFunction(...args), timeoutMs);
  };
}

const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export const currencies = {
  eur: 'EUR €',
  usd: 'USD $',
  gbp: 'GBP £',
  aed: 'AED د.إ',
  brl: 'BRL R$',
  chf: 'CHF ₣',
  cad: 'CAD $',
  aud: 'AUD $',
  inr: 'INR ₹',
  cny: 'CNY ¥',
  dkk: 'DKK',
  hkd: 'HK$',
  jpy: 'JPY ¥',
  krw: 'KRW ₩',
  nzd: 'NZ$',
  sek: 'SEK'
};

export const currencySymbols = {
  eur: '€',
  usd: '$',
  gbp: '£',
  aed: 'د.إ',
  brl: 'R$',
  chf: '₣',
  cad: '$',
  aud: '$',
  inr: '₹',
  cny: '¥',
  dkk: 'DKK',
  hkd: 'HK$',
  jpy: '¥',
  krw: '₩',
  nzd: 'NZ$',
  sek: 'SEK'
};

export const relevantCurrencies = {
  cad: 'CAD $',
  eur: 'EUR €',
  gbp: 'GBP £',
  usd: 'USD $'
};

export const countries = {
  au: 'Australia',
  at: 'Austria',
  be: 'Belgium',
  br: 'Brazil',
  bg: 'Bulgaria',
  ca: 'Canada',
  hr: 'Croatia',
  cy: 'Cyprus',
  cz: 'Czech Republic',
  dk: 'Denmark',
  ee: 'Estonia',
  fi: 'Finland',
  fr: 'France',
  de: 'Germany',
  gi: 'Gibraltar',
  gr: 'Greece',
  hk: 'Hong Kong',
  hu: 'Hungary',
  in: 'India',
  id: 'Indonesia',
  ie: 'Ireland',
  it: 'Italy',
  jp: 'Japan',
  lv: 'Latvia',
  li: 'Liechtenstein',
  lt: 'Lithuania',
  lux: 'Luxembourg',
  my: 'Malaysia',
  mlt: 'Malta',
  mx: 'Mexico',
  nl: 'Netherlands',
  nz: 'New Zealand',
  no: 'Norway',
  pl: 'Poland',
  pt: 'Portugal',
  ro: 'Romania',
  sg: 'Singapore',
  sk: 'Slovakia',
  si: 'Slovenia',
  es: 'Spain',
  se: 'Sweden',
  ch: 'Switzerland',
  th: 'Thailand',
  ae: 'United Arab Emirates',
  gb: 'United Kingdom',
  us: 'United States',
  ad: 'Andorra',
  xk: 'Kosovo',
  mc: 'Monaco',
  me: 'Montenegro',
  sm: 'San Marino',
  va: 'Vatican City'
};

export const tipDirectCountries = {
  at: 'Austria',
  be: 'Belgium',
  hr: 'Croatia',
  cy: 'Cyprus',
  ee: 'Estonia',
  fi: 'Finland',
  fr: 'France',
  de: 'Germany',
  gr: 'Greece',
  ie: 'Ireland',
  it: 'Italy',
  lv: 'Latvia',
  lt: 'Lithuania',
  lu: 'Luxembourg',
  mt: 'Malta',
  nl: 'Netherlands',
  pt: 'Portugal',
  sk: 'Slovakia',
  si: 'Slovenia',
  es: 'Spain',
  gb: 'United Kingdom',
  us: 'United States',
  ca: 'Canada'
};

export const countryToRegion = {
  au: 'US',
  at: 'EU',
  be: 'EU',
  br: 'US',
  bg: 'EU',
  ca: 'US',
  hr: 'EU',
  cy: 'EU',
  cz: 'EU',
  dk: 'EU',
  ee: 'EU',
  fi: 'EU',
  fr: 'EU',
  de: 'EU',
  gi: 'EU',
  gr: 'EU',
  hk: 'US',
  hu: 'EU',
  in: 'US',
  id: 'US',
  ie: 'EU',
  it: 'EU',
  jp: 'US',
  lv: 'EU',
  li: 'EU',
  lt: 'EU',
  lux: 'EU',
  my: 'US',
  mlt: 'EU',
  mx: 'US',
  nl: 'EU',
  nz: 'US',
  no: 'EU',
  pl: 'EU',
  pt: 'EU',
  ro: 'EU',
  sg: 'US',
  sk: 'EU',
  si: 'EU',
  es: 'EU',
  se: 'EU',
  ch: 'EU',
  th: 'US',
  ae: 'US',
  gb: 'EU',
  us: 'US',
  ad: 'EU',
  xk: 'EU',
  mc: 'EU',
  me: 'EU',
  sm: 'EU',
  va: 'EU'
};

export const supportedLanguages = [
  'en',
  'ca',
  'da',
  'de',
  'es',
  'fi',
  'fr',
  'it',
  'ja',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv'
];
export const languageCodesToLanguageNames = {
  en: 'English',
  fr: 'French',
  es: 'Spanish',
  de: 'German',
  no: 'Norwegian',
  pl: 'Polish',
  ru: 'Russian',
  pt: 'Portuguese',
  nl: 'Dutch',
  sv: 'Swedish',
  ja: 'Japanese',
  da: 'Danish',
  it: 'Italian',
  fi: 'Finnish',
  ca: 'Catalan'
};
export function langCodeToName(langCode) {
  return languageCodesToLanguageNames[langCode] ?? capitalize(langCode);
}

const currenciesDecimalCountOverrides = {};
function getCurrencyDecimalPoints(currency) {
  return currenciesDecimalCountOverrides[currency] ?? 2;
}

export function date2day(date) {
  return days[date.getDay()];
}

export function date2str(date) {
  const d = new Date(date);
  return `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(
    -2
  )}`;
}

export function getStartOfDayInZeroTimezone(d) {
  const date = startOfDay(new Date(d));
  return new Date(date.getTime() + getFormattedOffsetTime(date.getTimezoneOffset()));
}

export function seasonByDate(seasons, date) {
  const d = new Date(date);
  return seasons.find(season => new Date(season.start) <= d && endOfDay(new Date(season.end)) > d);
}

export function date2strSlash(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function parseSeasonDates(seasons) {
  return seasons.map(season => ({
    ...season,
    start: parse(season.start.toString().split('T')[0], 'yyyy-MM-dd', new Date()),
    end: endOfDay(parse(season.end.toString().split('T')[0], 'yyyy-MM-dd', new Date()))
  }));
}

export function currencySign(symbol, withCurrencyName = true) {
  const relevantCurrencies = withCurrencyName ? currencies : currencySymbols;

  if (typeof symbol !== 'string') return relevantCurrencies.eur;
  return relevantCurrencies[symbol.toLowerCase()] ?? '';
}

export function getCurrency() {
  return localStorage.getItem('curr');
}

export function getCurrencySymbol() {
  return currencySign(getCurrency());
}

export function getParticipantsTotalCapacityEffect(participants) {
  return participants.reduce(
    (sum, participant) => participant.count * (participant.capacityEffect ?? 1) + sum,
    0
  );
}

export function strToAbsoluteDate(dateString) {
  const [y, m, d] = dateString.split('T')[0].split('-');
  return new Date(Number(y), Number(m) - 1, Number(d), 0, 0, 0, 0);
}

export function formatTimeslot({ hours, minutes }) {
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export function isIntervalSeason(season) {
  if (!season) false;
  return season.tag === 'interval';
}

export function getPaymentStatus(bookingStatus, paymentDetails, bookingEditingDetails) {
  if (bookingStatus === 'refunded') {
    return PaymentStatuses.Refunded;
  }

  if (isNil(bookingEditingDetails)) {
    return paymentDetails?.tag === PaymentProviders.OnArrival &&
      paymentDetails?.status === 'unfulfilled'
      ? PaymentStatuses.Unpaid
      : PaymentStatuses.Paid;
  }

  const { stripe, onAccount, onArrival } = bookingEditingDetails;

  if ((stripe?.total?.amount || onAccount?.total?.amount) && !onArrival?.total?.amount) {
    return PaymentStatuses.Paid;
  } else if (!stripe?.total?.amount && !onAccount?.total?.amount && onArrival?.total?.amount) {
    return PaymentStatuses.Unpaid;
  }
  return PaymentStatuses.PartiallyPaid;
}

export function getTotalPaidWithoutFees(
  paymentDetails,
  bookingEditingDetails,
  fees,
  priceBreakdown
) {
  if (bookingEditingDetails) {
    return (
      bookingEditingDetails.stripe.total.amount +
      bookingEditingDetails.onAccount.total.amount -
      (bookingEditingDetails.onAccount.auxiliaryFee.amount +
        bookingEditingDetails.stripe.auxiliaryFee.amount)
    );
  }

  if (paymentDetails?.tag === PaymentProviders.Redeam || !paymentDetails?.tag) {
    return priceBreakdown.total;
  } else if (
    paymentDetails.tag === PaymentProviders.OnArrival &&
    paymentDetails.status === 'unfulfilled'
  ) {
    return 0;
  } else {
    const auxFeeAmount = fees?.some(f => f.type.includes('outside'))
      ? paymentDetails.applicationFee.amount
      : 0;
    return paymentDetails.payment.amount - auxFeeAmount;
  }
}

export function getTotalUnpaidWithoutFees(paymentDetails, bookingEditingDetails, fees) {
  if (!paymentDetails?.tag) {
    return 0;
  }
  if (bookingEditingDetails) {
    return (
      bookingEditingDetails.onArrival.total.amount -
      bookingEditingDetails.onArrival.auxiliaryFee.amount
    );
  }

  if (
    paymentDetails.tag === PaymentProviders.OnArrival &&
    paymentDetails.status === 'unfulfilled'
  ) {
    const auxFeeAmount = fees?.some(f => f.type.includes('outside'))
      ? paymentDetails.applicationFee.amount
      : 0;

    return paymentDetails.payment.amount - auxFeeAmount;
  } else {
    return 0;
  }
}

export function getTotalWithoutFeesFromEditingDetails(bookingEditingDetails) {
  if (!bookingEditingDetails) return;

  return bookingEditingDetails.total.total.amount - bookingEditingDetails.total.auxiliaryFee.amount;
}

export function getTotalFeesFromBreakdown(breakdown) {
  return breakdown.elements
    .filter(e => e.type == 'fee')
    .map(e => e.price)
    .reduce((sum, e) => (sum += e), 0);
}
export function noop() {}

export function constructFontDetails(fontUrl, fontId) {
  const fontFamily = `Company-custom-font-${fontId}`;
  const fontObject = {
    fontFamily,
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `url(${fontUrl})`
  };

  return [fontFamily, fontObject];
}

export function createReducer(reducers, initialState) {
  return function(state = initialState, action) {
    if (typeof reducers[action.type] === 'function')
      return reducers[action.type](state, action.payload);

    return state;
  };
}

const id = x => x;

export function createAction(type, payloadShaper = id) {
  return (...payload) => ({
    type,
    payload: payloadShaper(...payload)
  });
}

export function moneyFromDecimalToInteger({ amount, currency }) {
  const decimals = getCurrencyDecimalPoints(currency);
  const atomic = Math.pow(10, decimals);

  return Math.round(amount * atomic);
}

export function moneyFromIntegerToDecimal({ amount, currency }) {
  const isNegative = Math.sign(amount) < 0;
  const decimals = getCurrencyDecimalPoints(currency);
  const atomic = Math.pow(10, decimals);
  const integer = Math.floor(Math.abs(amount) / atomic);
  const floating = (Math.abs(amount) % atomic) / atomic;

  const res = integer + floating;
  return isNegative ? res * -1 : res;
}

export const isCurrencyZeroDecimal = currency => {
  return ZeroDecimalCurrencies.indexOf(currency) > -1;
};

export function formatMoney({ amount, currency }) {
  const isSelectedCurrencyZeroDecimal = isCurrencyZeroDecimal(currency);
  const decimals = getCurrencyDecimalPoints(currency);
  const atomic = Math.pow(10, decimals);

  if (isSelectedCurrencyZeroDecimal) {
    return `${amount}`;
  }
  return `${Math.floor(amount / atomic)}.${String(amount % atomic).padStart(decimals, 0)}`;
}

export function formatMoneyAndCurrency({ amount, currency }) {
  return `${currency.toUpperCase()} ${formatMoney({ amount, currency })}`;
}

export function formatMoneyAndCurrencySymbol({ amount, currency }, withCurrencyName = true) {
  return `${currencySign(currency, withCurrencyName)}${formatMoney({
    amount,
    currency
  })}`;
}

export function convertArrayToObject(array, key) {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    };
  }, initialValue);
}

export function getFormattedOffsetTime(offset) {
  // offset from date is always in minutes and with opposite sign +-
  return offset * TIME_UNITS.minutes * -1;
}

export const TIME_UNITS = {
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24
};

function checkForUndefined(value) {
  return !value ? 0 : value;
}

export function getCutoffTimeInMs(cutoffTimes) {
  if (!cutoffTimes) return 0;
  const daysInSec = checkForUndefined(cutoffTimes.days) * 60 * 60 * 24;
  const hoursInSec = checkForUndefined(cutoffTimes.hours) * 60 * 60;
  const minutesInSec = checkForUndefined(cutoffTimes.minutes) * 60;
  return (daysInSec + hoursInSec + minutesInSec) * 1000;
}

function addZeroIfNeeded(arg) {
  const str = String(arg).replace('-', '');
  if (str.length == 1) return `0${str}`;
  return str;
}

export function constructDateStringWithTimezone(date, timezoneName) {
  // returns string like this: 'YYYY-MM-DDTHH:MM:00.000Z'
  const offsetInMs = getTimezoneOffset(timezoneName, date);
  const symbol = String(offsetInMs)[0] == '-' ? '-' : '+';
  const hours = Math.floor(offsetInMs / (1000 * 60 * 60));
  const minutesWithSymbol = (offsetInMs % (1000 * 60 * 60)) / 60000;
  const minutes =
    String(minutesWithSymbol)[0] == '-' ? String(minutesWithSymbol).slice(1) : minutesWithSymbol;
  const offsetString = symbol + `${addZeroIfNeeded(hours)}:${addZeroIfNeeded(minutes)}`;
  const result = `${date.getFullYear()}-${addZeroIfNeeded(date.getMonth() + 1)}-${addZeroIfNeeded(
    date.getDate()
  )}T${addZeroIfNeeded(date.getHours())}:${addZeroIfNeeded(date.getMinutes())}:00.0${offsetString}`;
  return result;
}

export function eqBookingDate(x, y) {
  //if different types -> not equal
  if ('timeslot' in x !== 'timeslot' in y) {
    return false;
  }

  const datesEqual = new Date(x.date).getTime() === new Date(y.date).getTime();
  if (!datesEqual) {
    return false;
  }

  //if both has timeslots and timeslots are not equal -> not equal
  if (
    x.timeslot !== undefined &&
    y.timeslot !== undefined &&
    timeslotComparator(x.timeslot, y.timeslot) !== 0
  ) {
    return false;
  }

  return true;
}

export function timeslotComparator(a, b) {
  if (a.hours - b.hours !== 0) {
    return normalize(-1, 1, a.hours - b.hours);
  }

  return normalize(-1, 1, a.minutes - b.minutes);
}

function normalize(min, max, val) {
  if (val < min) return min;
  if (val > max) return max;

  return val;
}

export function isProductEnabled(product, companyProductDetails = {}) {
  if (product === SignupType.TicketingSystem) {
    //TS is disabled for the US Region
    return (
      process.env.REGION !== 'US' &&
      (!companyProductDetails || companyProductDetails.TicketingSystem)
    );
  }

  return !companyProductDetails || companyProductDetails.TipDirect;
}

// Script to Programmatically Lighten or Darken a hex color
// https://stackoverflow.com/a/13542669/16580563
export const changeColorBrightness = (p, c0, c1, l) => {
  let r,
    g,
    b,
    P,
    f,
    t,
    h,
    i = parseInt,
    m = Math.round,
    a = typeof c1 == 'string';
  if (
    typeof p != 'number' ||
    p < -1 ||
    p > 1 ||
    typeof c0 != 'string' ||
    (c0[0] != 'r' && c0[0] != '#') ||
    (c1 && !a)
  )
    return null;
  const globalObj = {};
  if (!globalObj.pSBCr)
    globalObj.pSBCr = d => {
      let n = d.length,
        x = {};
      if (n > 9) {
        ([r, g, b, a] = d = d.split(',')), (n = d.length);
        if (n < 3 || n > 4) return null;
        (x.r = i(r[3] == 'a' ? r.slice(5) : r.slice(4))),
          (x.g = i(g)),
          (x.b = i(b)),
          (x.a = a ? parseFloat(a) : -1);
      } else {
        if (n == 8 || n == 6 || n < 4) return null;
        if (n < 6) d = '#' + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : '');
        d = i(d.slice(1), 16);
        if (n == 9 || n == 5)
          (x.r = (d >> 24) & 255),
            (x.g = (d >> 16) & 255),
            (x.b = (d >> 8) & 255),
            (x.a = m((d & 255) / 0.255) / 1000);
        else (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1);
      }
      return x;
    };
  (h = c0.length > 9),
    (h = a ? (c1.length > 9 ? true : c1 == 'c' ? !h : false) : h),
    (f = globalObj.pSBCr(c0)),
    (P = p < 0),
    (t =
      c1 && c1 != 'c'
        ? globalObj.pSBCr(c1)
        : P
        ? { r: 0, g: 0, b: 0, a: -1 }
        : { r: 255, g: 255, b: 255, a: -1 }),
    (p = P ? p * -1 : p),
    (P = 1 - p);
  if (!f || !t) return null;
  if (l) (r = m(P * f.r + p * t.r)), (g = m(P * f.g + p * t.g)), (b = m(P * f.b + p * t.b));
  else
    (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
      (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
      (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
  (a = f.a),
    (t = t.a),
    (f = a >= 0 || t >= 0),
    (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
  if (h)
    return (
      'rgb' + (f ? 'a(' : '(') + r + ',' + g + ',' + b + (f ? ',' + m(a * 1000) / 1000 : '') + ')'
    );
  else
    return (
      '#' +
      (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
        .toString(16)
        .slice(1, f ? undefined : -2)
    );
};

export const getSortedGuidesForTeam = (users, currentUserId) => {
  return users.sort((a, b) => {
    // 1. Move the current user to the top
    if (a._id === currentUserId) return -1;
    if (b._id === currentUserId) return 1;

    // 2. Sort users with avatarUrl first, then alphabetically by firstName and lastName
    if (a.profile.avatarUrl && b.profile.avatarUrl) {
      return (
        a.profile?.firstName.localeCompare(b.profile?.firstName) ||
        a.profile?.lastName.localeCompare(b.profile?.lastName)
      );
    }
    if (a.profile.avatarUrl) return -1;
    if (b.profile.avatarUrl) return 1;

    // 3. Sort alphabetically users without avatarUrl
    return (
      a.profile?.firstName.localeCompare(b.profile?.firstName) ||
      a.profile?.lastName.localeCompare(b.profile?.lastName)
    );
  });
};
